import request from "../utils/request";

//保存个人资料
export const MaterialSave = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/Material/save",
    method: "post",
    data: query,
  });
};

//创建收藏夹
export const CollectNew = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/Collect/New",
    method: "post",
    data: query,
  });
};

//删除收藏夹
export const CollectRemove = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/Collect/Remove",
    method: "post",
    data: query,
  });
};

//获取收藏夹数据
export const CollectList = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/Collect/List",
    method: "get",
    params: query,
  });
};

//获取评论数据
export const CommentList = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/Comment/List",
    method: "get",
    params: query,
  });
};

//获取历史数据
export const HistoryList = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/History/List",
    method: "get",
    params: query,
  });
};

//修改密码
export const SetUpPassword = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "UserMable/SetUp/Password",
    method: "post",
    data: query,
  });
};
