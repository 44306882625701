<template>
  <div class="Creation">
    <div class="Info">
      <div class="ioc"></div>
      <div class="title">创作中心</div>
      <div class="grade">等级：<span>V1</span></div>
    </div>
    <div class="operate">
      <div class="block" @click="openDuiwu">
        <div class="ico duiwu"></div>
        <div class="text">我的队伍</div>
      </div>
      <div class="block" @click="openWriting">
        <div class="ico zuopin"></div>
        <div class="text">写文章</div>
      </div>
      <div class="block">
        <div class="ico wenzhang"></div>
        <div class="text">文章管理</div>
      </div>
    </div>
    <div class="binSetList">
      <div class="block">
        <div class="ico yaoqing"></div>
        <div class="text">我的邀请</div>
      </div>
      <div class="block">
        <div class="ico qianbao"></div>
        <div class="text">我的钱包</div>
      </div>
      <div class="block">
        <div class="ico kefu"></div>
        <div class="text">帮助中心</div>
      </div>
      <div class="block">
        <div class="ico banquan"></div>
        <div class="text">版权法律</div>
      </div>
    </div>
    <Duiwu ref="Duiwu"></Duiwu>
    <Writing ref="Writing"></Writing>
  </div>
</template>

<script>
import Duiwu from "./Creation/Duiwu";
import Writing from "./Creation/Writing";
export default {
  components: {
    Duiwu,
    Writing,
  },
  methods: {
    openWriting() {
      this.$refs.Writing.openWindow();
    },
    openDuiwu() {
      this.$refs.Duiwu.openWindows();
    },
  },
};
</script>

<style scoped lang="scss">
.Creation {
  width: 270px;
  height: 400px;
  border: 1px solid #c7c1dd;
  border-radius: 13px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
  .Info {
    width: 100%;
    height: 50px;
    position: relative;
    user-select: none;
    margin-bottom: 30px;
    .ioc {
      position: absolute;
      left: 15px;
      top: 20px;
      background-image: url("../../../../assets/Image/ico/userCreation.png");
      background-size: 100% 100%;
      width: 24px;
      height: 25px;
    }
    @mixin text {
      color: #666666;
      font-size: 14px;
      position: absolute;
      top: 25px;
      span {
        margin-left: 5px;
        color: rgba(45, 136, 171, 0.81);
        cursor: pointer;
      }
    }
    .title {
      @include text;
      left: 50px;
    }
    .grade {
      @include text;
      left: 150px;
    }

    //background-color: #ff725e;
    //ziyuan243.png
  }
  .operate {
    display: flex;
    justify-content: space-around;
    user-select: none;
    margin-bottom: 30px;
    .block {
      //width: 100%;
      height: 50px;
      text-align: center;
      cursor: pointer;
      .ico {
        width: 25px;
        height: 25px;
        display: inline-block;
        background-size: 100% 100%;
      }
      .text {
        color: #666666;
        font-size: 13px;
      }
      .duiwu {
        background-image: url("../../../../assets/Image/ico/duiwu.png");
      }
      .zuopin {
        background-image: url("../../../../assets/Image/ico/zuopin.png");
      }
      .wenzhang {
        background-image: url("../../../../assets/Image/ico/wenzhang.png");
      }
    }
  }
  .binSetList {
    user-select: none;
    margin: 0 auto;
    width: 280px;

    .block {
      display: inline-block;
      width: 100%;
      height: 55px;
      position: relative;

      .ico {
        position: absolute;
        left: 49px;
        top: 14px;
        display: inline-block;
        width: 26px;
        height: 26px;
        background-size: 100% 100%;
      }

      .text {
        position: absolute;
        left: 90px;
        top: 18px;
        display: inline-block;
        cursor: pointer;
        font-size: 14px;
        color: #666666;
      }
    }
    .yaoqing {
      background-image: url("../../../../assets/Image/ico/yaoqing.png");
    }
    .qianbao {
      background-image: url("../../../../assets/Image/ico/qianbao.png");
    }
    .kefu {
      background-image: url("../../../../assets/Image/ico/kefu.png");
    }
    .banquan {
      background-image: url("../../../../assets/Image/ico/banquan.png");
    }
  }
}
</style>
