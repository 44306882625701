<template>
  <el-dialog v-model="view" width="600px">
    <el-form class="form" label-width="70px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="3QID">
            <span v-if="form.User_id">{{ form.User_id }}</span>
            <span v-else><el-tag type="warning">未认证</el-tag></span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="加入时间">
            {{ form.NewTime }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="笔名" :required="true">
        <el-input v-model="form.PenName" class="PenName"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio v-model="form.Gender" label="男">男</el-radio>
        <el-radio v-model="form.Gender" label="女">女</el-radio>
        <el-radio v-model="form.Gender" label="保密">保密</el-radio>
      </el-form-item>
      <el-form-item label="居住地">
        <el-input v-model="form.UserLive" class="PenName"></el-input>
      </el-form-item>
      <el-form-item label="所在行业">
        <el-input v-model="form.UserIndustry" class="PenName"></el-input>
      </el-form-item>
      <el-form-item label="职业经历">
        <el-input v-model="form.UserProfession" class="PenName"></el-input>
      </el-form-item>
      <el-form-item label="个人简介">
        <el-input v-model="form.UserIntroduction" class="PenName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import { MaterialSave } from "../../../../../api/UserMable";

export default {
  data() {
    return {
      view: false,
      form: {},
    };
  },
  methods: {
    save() {
      let form = JSON.parse(JSON.stringify(this.form));
      let _id = form._id;
      let Gender = form.Gender;
      let UserLive = form.UserLive;
      let UserIndustry = form.UserIndustry;
      let UserProfession = form.UserProfession;
      let UserIntroduction = form.UserIntroduction;
      let PenName = form.PenName;
      if (PenName) {
        let UpData = {
          _id,
          PenName,
          Gender,
          UserLive,
          UserIndustry,
          UserProfession,
          UserIntroduction,
        };
        MaterialSave(UpData).then((res) => {
          if (res.code == 0) {
            let data = res.data;
            this.$store.state.UserInfo = data;
            ElMessage.success({
              message: res.message,
              type: "success",
            });
            this.close();
          } else {
            ElMessage.warning({
              message: res.message,
              type: "warning",
            });
          }
        });
      } else {
        ElMessage.warning({
          message: "笔名为空，请重新输入",
          type: "warning",
        });
      }
    },
    open(data) {
      console.log(data);
      this.form = data;
      this.view = true;
    },
    close() {
      this.form = {};
      this.view = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  height: 320px;
}

.PenName {
  width: 350px;
}
</style>
