<template>
  <!--  队伍管理-->
  <div class="windows" v-if="windows">
    <div class="Notduiwu">
      <div
        class="closeMain"
        :style="focus.closeMainStart"
        @click="closeWindows"
        @mouseenter="closeMainfocus"
        @mouseout="closeMainfocusNot"
      ></div>
      <!--      创建与加入-->
      <div class="create" v-if="NotView.create">
        <div class="block">
          <div class="bgBlock"></div>
          <div class="button" @click="opennewTeam">创建队伍</div>
        </div>
        <!--        分割线-->
        <div class="segmentation"></div>
        <div class="block">
          <div class="bgBlock"></div>
          <div class="button" @click="openjoinTeam">加入队伍</div>
        </div>
      </div>
      <!--      创建队伍-->
      <div class="newTeam" v-if="NotView.newTeam">
        <div class="title">让我们聆听你的新的队伍名称</div>
        <input
          :style="focus.newTeamStart"
          type="text"
          class="input"
          @focus="newTeamfocus"
          @focusout="newTeamfocusNot"
        />
        <div class="nav">
          <div class="finish">提交</div>
          <div class="close" @click="closenewTeam">取消</div>
        </div>
      </div>
      <!--      加入队伍-->
      <div class="joinTeam" v-if="NotView.joinTeam">
        <div class="title">让我们寻找最棒的队伍</div>
        <input
          :style="focus.newTeamStart"
          type="text"
          class="input"
          @focus="newTeamfocus"
          @focusout="newTeamfocusNot"
        />
        <div class="nav">
          <div class="finish" @click="match">匹配</div>
          <div class="close" @click="closejoinTeam">取消</div>
        </div>
      </div>
      <!--      匹配队伍-->
      <div class="matchTeam" v-if="NotView.matchTeam">
        <div class="title">匹配队伍</div>
        <div class="main">
          <div class="data"></div>
          <div class="data"></div>
          <div class="data"></div>
          <div class="data"></div>
        </div>
        <div class="nav">
          <div class="finish">申请</div>
          <div class="close" @click="closematchTeam">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windows: false,
      NotView: {
        // 创建与加入
        create: false,
        // 创建
        newTeam: false,
        // 加入
        joinTeam: false,
        // 匹配队伍
        matchTeam: false,
      },
      focus: {
        newTeamStart: "",
        closeMainStart: "",
      },
    };
  },
  methods: {
    openWindows() {
      this.windows = true;
      this.NotView.create = true;
    },
    closematchTeam() {
      this.NotView.create = true;
      this.NotView.matchTeam = false;
    },
    match() {
      this.NotView.matchTeam = true;
      this.NotView.joinTeam = false;
    },
    closenewTeam() {
      this.NotView.newTeam = false;
      this.NotView.create = true;
    },
    closejoinTeam() {
      this.NotView.joinTeam = false;
      this.NotView.create = true;
    },
    openjoinTeam() {
      this.NotView.joinTeam = true;
      this.NotView.create = false;
    },

    opennewTeam() {
      this.NotView.newTeam = true;
      this.NotView.create = false;
    },
    closeMainfocus() {
      this.focus.closeMainStart = "transform: rotate(180deg)";
    },
    closeMainfocusNot() {
      this.focus.closeMainStart = "transform: rotate(0deg)";
    },
    newTeamfocus() {
      this.focus.newTeamStart = "border: 2.5px solid rgb(86, 70, 235)";
    },
    newTeamfocusNot() {
      this.focus.newTeamStart = "";
    },

    closeWindows() {
      this.focus.closeMainStart = "";
      this.windows = false;
      this.NotView.create = false;
      this.NotView.newTeam = false;
      this.NotView.joinTeam = false;
      this.NotView.matchTeam = false;
    },
  },
};
</script>

<style scoped lang="scss">
.windows {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .Notduiwu {
    width: 1200px;
    height: 600px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -300px;
    background-color: #ffffff;
    border-radius: 12px;

    .closeMain {
      background-image: url("../../../../../assets/Image/ico/guanbi.png");
      background-size: 100% 100%;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 10;
      transition: 0.5s;

      :hover {
        transform: rotate(90deg);
      }
    }

    .create {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      .segmentation {
        position: absolute;
        width: 1px;
        height: 360px;
        background-color: #666666;
        left: 50%;
        top: 50%;
        margin-top: -180px;
      }

      .block {
        width: 50%;
        height: 100%;
        position: relative;

        .bgBlock {
          position: absolute;
          left: 50%;
          top: 50px;
          margin-left: -150px;
          width: 300px;
          height: 250px;
          border-radius: 12px;
          background-color: #42b983;
        }

        .button {
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 15px;
          background-color: rgba(162, 165, 255, 0.81);
          color: #ffffff;
          border-radius: 10px;
          position: absolute;
          user-select: none;
          cursor: pointer;
          left: 50%;
          margin-left: -60px;
          top: 500px;
        }
      }
    }

    @mixin block() {
      width: 120px;
      height: 40px;
      line-height: 40px;
      background-color: rgba(82, 69, 255, 0.81);
      color: #ffffff;
      text-align: center;
      border-radius: 14px;
      cursor: pointer;
      user-select: none;
    }
    @mixin Team {
      position: relative;
      width: 100%;
      height: 100%;
      .title {
        position: absolute;
        width: 100%;
        top: 140px;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
      }
      .input {
        position: absolute;
        width: 500px;
        height: 40px;
        text-align: center;
        outline: none;
        font-size: 20px;
        top: 220px;
        left: 50%;
        margin-left: -250px;
        border-radius: 15px;
        border: none;
        border: 1px solid #c7c1dd;
        transition: 0.5s all;
      }
      .nav {
        width: 400px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 300px;
        display: flex;
        justify-content: space-around;
        margin-left: -200px;

        .finish {
          @include block;
        }

        .close {
          @include block;
        }
      }
    }

    .newTeam {
      @include Team;
    }

    .joinTeam {
      @include Team;
    }

    .matchTeam {
      position: relative;
      width: 100%;
      height: 100%;

      .title {
        position: absolute;
        width: 100%;
        top: 20px;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
      }

      .main {
        position: absolute;
        width: 800px;
        height: 400px;
        left: 50%;
        margin-left: -400px;
        top: 90px;
        overflow-y: auto;

        .data {
          width: 100%;
          height: 140px;
          background-color: #efefef;
          border-radius: 10px;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }

      .nav {
        width: 400px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 520px;
        display: flex;
        justify-content: space-around;
        margin-left: -200px;

        .finish {
          @include block;
        }

        .close {
          @include block;
        }
      }
    }
  }
}
</style>
