<template>
  <el-dialog
    v-model="view"
    width="700px"
    title="评论"
    :show-overflow-tooltip="true"
  >
    <el-table :data="tableData">
      <el-table-column prop="ArticleTitle" label="文章标题"></el-table-column>
      <el-table-column prop="Text" label="内容" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="NewTime" label="时间"></el-table-column>
      <el-table-column width="80" align="align">
        <el-button type="text">进入</el-button>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="query.pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { CommentList } from "../../../../../api/UserMable";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      view: false,
      //表格数据
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
    };
  },
  methods: {
    getData() {
      let UserInfo = this.$store.state.UserInfo;
      let skip = this.query.pageIndex;
      let UserMobile = UserInfo.PhoneMobile;
      CommentList({ skip, UserMobile }).then((res) => {
        if (res.code == 0) {
          this.query.pageTotal = res.number;
          this.tableData = res.sum;
        } else {
          ElMessage.warning({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    handlePageChange(val) {
      this.loading = true;
      this.query.pageIndex = val;
      this.getData();
    },
    save() {},
    open() {
      this.getData();
      this.view = true;
    },
    close() {
      this.view = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  height: 320px;
}

.PenName {
  width: 350px;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 300px !important;
}
</style>
