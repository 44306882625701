<template>
  <div class="header" v-bind:style="view.header">
    <div class="nav" @click="open()"></div>
    <div class="nav_blocks" v-bind:style="ng" @click.stop="exit(1)">
      <div class="ng">
        <div class="main" v-bind:style="main" @click.stop="exit(0)">
          <div class="navTitle"></div>
          <LoginMable v-if="!view.loginStart" ref="LoginMable"></LoginMable>
          <UserMable v-if="view.loginStart"></UserMable>
          <Creation v-if="view.loginStart"></Creation>
          <div class="fnBlock" v-if="view.loginStart" @click="closeInfo">
            退出
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nav from "@/assets/Image/public/nav_1.png";
import nav_logo_m from "@/assets/Image/mobile/home/nav_logo.png";
import LoginMable from "./page/LoginMable.vue";
import UserMable from "./page/UserMable";
import Creation from "./page/Creation";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      nav,
      nav_logo_m,
      ng: "",
      main: "right:-30re,",
      form: {},
      view: {
        s: true,
        header: "",
        loginStart: false,
      },
    };
  },
  components: {
    LoginMable,
    UserMable,
    Creation,
  },
  mounted() {
    this.initStart();
    this.scroll();
  },
  methods: {
    //检测是否存在状态，则更新账号状态
    initStart() {
      let UserInfo = this.$store.state.UserInfo;
      if (UserInfo.PhoneMobile) {
        this.view.loginStart = true;
      } else {
        this.view.loginStart = false;
      }
    },
    //退出当前账号
    closeInfo() {
      this.$confirm("是否确定退出当前的账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.state.UserInfo = {};
          this.initStart();
          ElMessage.success({
            message: "退出成功",
            type: "success",
          });
        })
        .catch(() => {});
    },
    scroll() {
      window.addEventListener("scroll", () => {
        let top = document.documentElement.scrollTop;
        if (top >= 100) {
          this.view.header =
            "position: fixed;left: 0;top: 0;background-color:#c7c1dd";
        } else {
          this.view.header = "";
        }
      });
    },
    close_view() {
      document.body.style.overflow = "auto";
    },
    open() {
      this.ng = "display:block;";
      setTimeout(() => {
        this.main = "right:0";
        document.body.style.overflow = "hidden";
        if (this.view.loginStart == false) {
          this.$refs.LoginMable.closeInfo();
        }
      }, 1);
    },
    exit(str) {
      if (str == 0) {
        return;
      }
      this.main = "right:-30rem";
      document.body.style.overflow = "auto";
      setTimeout(() => {
        this.ng = "";
      }, 300);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  background-color: #c7c1dd;
  height: 40px;
  position: relative;
  z-index: 20;
  .nav {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: 1rem;
    background-image: url("../../../assets/Image/public/nav_1.png");
    background-size: 100% 100%;
    cursor: pointer;
  }

  .nav_blocks {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 15;
    display: none;

    .ng {
      position: relative;
      width: 100%;
      height: 100%;

      .main {
        position: absolute;
        width: 20rem;
        height: 100%;
        right: -15rem;
        top: 0;
        background-color: #efefef;
        transition: 0.3s;
        overflow-y: auto;
        a {
          color: black;
          text-decoration: none;
          outline: none;
        }

        .navTitle {
          background-color: #c7c1dd;
          width: 100%;
          height: 40px;
          line-height: 4rem;
          text-align: center;
          margin-bottom: 0.8rem;
          color: #ffffff;
          font-size: 1rem;
        }
        .fnBlock {
          cursor: pointer;
          width: 200px;
          text-align: center;
          font-size: 13px;
          color: #ffffff;
          background-color: rgba(235, 76, 55, 0.81);
          height: 40px;
          line-height: 40px;
          margin: 0 auto;
          border-radius: 18px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
