<template>
  <div class="Writing" v-if="windows">
    <div class="Writing-block"></div>
    <div class="tinymce-box">
      <el-form class="form" label-width="70px">
        <el-form-item label="标题">
          <el-input v-model="form.PenName"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="关键词">
              <el-input v-model="form.PenName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-input v-model="form.PenName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-input v-model="form.PenName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <Tinymce ref="Tinymce"></Tinymce>
      <div class="dialog-footer">
        <el-button @click="closeWindows">取 消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
//import Tinymce from "../../../../../components/Tinymce";
export default {
  data() {
    return {
      windows: false,
      form: {},
    };
  },
  methods: {
    save() {},
    openWindow() {
      this.windows = true;
    },
    closeWindows() {
      this.windows = false;
    },
  },
  components: {
    // Tinymce,
  },
};
</script>

<style scoped lang="scss">
.Writing {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  .Writing-block {
    width: 100%;
    height: 100px;
  }
  .tinymce-box {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    .inputBlock {
      display: flex;
      span {
        display: inline;
      }
    }
    .dialog-footer {
      width: 100%;
      height: 40px;
      margin-top: 40px;
      text-align: right;
    }
  }
}
</style>
