<template>
  <div class="UserInfo">
    <div class="Avatar">
      <el-image
        class="img"
        :src="$store.state.UserInfo.Avatar"
        :preview-src-list="[$store.state.UserInfo.Avatar]"
      >
      </el-image>
    </div>
    <div class="binSetList">
      <div class="block">
        <div class="ico wode"></div>
        <div class="text" @click="openMaterial">编辑资料</div>
      </div>
      <div class="block">
        <div class="ico shoucang"></div>
        <div class="text" @click="openCollect">我的收藏</div>
      </div>
      <div class="block">
        <div class="ico wenda"></div>
        <div class="text" @click="openComment">我的评论</div>
      </div>
      <!--      <div class="block">-->
      <!--        <div class="ico dianzan"></div>-->
      <!--        <div class="text">我的点赞</div>-->
      <!--      </div>-->
      <div class="block">
        <div class="ico lishi"></div>
        <div class="text" @click="openHistory">搜索历史</div>
      </div>
      <div class="block">
        <div class="ico shezhi"></div>
        <div class="text" @click="openSetUp">设置</div>
      </div>
    </div>
  </div>
  <!--  编辑资料-->
  <Material ref="Material"></Material>
  <!--  我的收藏-->
  <Collect ref="Collect"></Collect>
  <!--  我的评论-->
  <Comment ref="Comment"></Comment>
  <!--  历史-->
  <Historys ref="Historys"></Historys>
  <!--  设置-->
  <SetUp ref="SetUp"></SetUp>
</template>

<script>
import Material from "./UserInfoList/Material";
import Collect from "./UserInfoList/Collect";
import Comment from "./UserInfoList/Comment";
import Historys from "./UserInfoList/History";
import SetUp from "./UserInfoList/SetUp";

export default {
  components: {
    Material,
    Collect,
    Comment,
    Historys,
    SetUp,
  },
  methods: {
    openSetUp() {
      this.$refs.SetUp.open();
    },
    openHistory() {
      this.$refs.Historys.open();
    },
    openCollect() {
      this.$refs.Collect.open();
    },
    openComment() {
      this.$refs.Comment.open();
    },
    openMaterial() {
      let UserInfo = JSON.parse(JSON.stringify(this.$store.state.UserInfo));
      this.$refs.Material.open(UserInfo);
    },
  },
};
</script>

<style scoped lang="scss">
.UserInfo {
  width: 270px;
  height: 476px;
  border: 1px solid #c7c1dd;
  border-radius: 13px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;

  .Avatar {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #c7c1dd;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    top: 20px;
    .img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }

  .binSetList {
    user-select: none;
    width: 280px;
    position: absolute;
    left: 50%;
    margin-left: -140px;
    top: 105px;

    .block {
      display: inline-block;
      width: 100%;
      height: 55px;
      position: relative;

      .ico {
        position: absolute;
        left: 49px;
        top: 14px;
        display: inline-block;
        width: 26px;
        height: 26px;
        background-size: 100% 100%;
      }

      .text {
        position: absolute;
        left: 90px;
        top: 18px;
        display: inline-block;
        cursor: pointer;
        font-size: 14px;
        color: #666666;
      }
    }
    .wode {
      background-image: url("../../../../assets/Image/ico/wode.png");
    }
    .shoucang {
      background-image: url("../../../../assets/Image/ico/shoucang.png");
    }
    .wenda {
      background-image: url("../../../../assets/Image/ico/wenda.png");
    }
    .dianzan {
      background-image: url("../../../../assets/Image/ico/dianzan.png");
    }
    .shezhi {
      background-image: url("../../../../assets/Image/ico/shezhi.png");
    }
    .lishi {
      background-image: url("../../../../assets/Image/ico/lishi.png");
    }
  }
}
</style>
