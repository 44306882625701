<template>
  <div>
    <div class="TitleGet">
      <span v-bind:class="{ s: view.s }" @click="openLogin">登陆</span>
      <span class="t">/</span>
      <span v-bind:class="{ s: !view.s }" @click="openReg">注册</span>
    </div>
    <div v-if="view.s">
      <div class="form">
        <div class="set">
          <input
            type="text"
            placeholder="请输入手机号码"
            v-model="form.PhoneMobile"
            autocomplete="off"
          />
        </div>
        <div class="set">
          <input
            type="password"
            v-model="form.Password"
            placeholder="请输入密码"
            autocomplete="new-password"
          />
        </div>
      </div>
      <div class="button">
        <div class="block" @click="login">登陆</div>
      </div>
      <div class="treaty">XXXXX法律条约</div>
    </div>
    <div v-if="!view.s">
      <div class="form">
        <div class="set">
          <input
            placeholder="请输入手机号码"
            v-model="form.PhoneMobile"
            name="UserName"
            autocomplete="”off”"
          />
        </div>
        <div class="set">
          <input
            placeholder="请输入密码"
            type="password"
            v-model="form.Password"
            name="UserPassword"
            autocomplete="new-password"
          />
        </div>
        <div class="set">
          <input
            placeholder="请输入验证码"
            v-model="form.Code"
            name="UserCode"
            autocomplete="”off”"
          />
          <div class="code">获取验证码</div>
        </div>
      </div>
      <div class="button">
        <div class="block">注册</div>
      </div>
      <div class="treaty">XXXXX法律条约</div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { verify } from "../../../../api/login";
export default {
  data() {
    return {
      view: {
        s: true,
      },
      form: {
        PhoneMobile: "",
        Password: "",
        Code: "",
      },
    };
  },
  methods: {
    login() {
      let PhoneMobile = this.form.PhoneMobile;
      let Password = this.form.Password;
      if (PhoneMobile && Password) {
        verify({ PhoneMobile, Password }).then((res) => {
          if (res.code == 0) {
            this.$store.state.UserInfo = res.sum;
            ElMessage.success({
              message: res.message,
              type: "success",
            });
            this.initInfo();
          } else if (res.code == 1) {
            ElMessage.warning({
              message: res.message,
              type: "warning",
            });
          } else {
            ElMessage.error(res.message);
          }
        });
      } else {
        ElMessage.warning({
          message: "请输入手机号码与密码",
          type: "warning",
        });
      }
    },
    openReg() {
      let s = this.view.s;
      if (s) {
        this.view.s = false;
        this.closeInfo();
      }
    },
    openLogin() {
      let s = this.view.s;
      if (!s) {
        this.view.s = true;
        this.closeInfo();
      }
    },
    closeInfo() {
      this.form.PhoneMobile = "";
      this.form.Password = "";
      this.form.Code = "";
    },
    initInfo() {
      this.$parent.initStart();
    },
  },
};
</script>

<style scoped lang="scss">
.TitleGet {
  text-align: center;
  font-size: 14px;
  user-select: none;
  margin-bottom: 10px;
  .s {
    font-weight: bold;
  }
  .t {
    margin-left: 10px;
    margin-right: 10px;
    cursor: default;
  }
  span {
    cursor: pointer;
    color: #666666;
  }
}

.form {
  width: 100%;

  .set {
    width: 310px;
    height: 40px;
    margin-bottom: 20px;
    position: relative;
    input {
      background: none;
      border: none;
      width: 90%;
      height: 100%;
      outline: none;
      text-indent: 1rem;
      border: 1px solid #dededf;
      border-radius: 13px;
      margin: 0 auto;
      display: block;
      transition: all 0.5s;
      //border-bottom: 1px solid #dededf;
    }

    input:focus {
      border: 1px solid #666666;
    }
    .code {
      width: 100px;
      height: 32px;
      position: absolute;
      right: 21px;
      top: 5px;
      background-color: #c7c1dd;
      border-radius: 10px;
      cursor: pointer;
      color: #666666;
      font-size: 12px;
      text-align: center;
      line-height: 32px;
    }
  }
}

.button {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-around;

  .block {
    background-color: #c7c1dd;
    color: #ffffff;
    font-size: 0.5rem;
    width: 200px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.5s;
  }
  :hover {
    transition: 0.5s all;
    background-color: #ff725e;
  }
}
.treaty {
  margin-top: 20px;
  text-align: center;
  color: #666666;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
