<template>
  <el-dialog
    v-model="view"
    width="700px"
    title="历史"
    :show-overflow-tooltip="true"
  >
    <el-table :data="tableData">
      <el-table-column prop="ArticleTitle" label="文章标题"></el-table-column>
      <el-table-column prop="NewTime" label="时间"></el-table-column>
      <el-table-column width="80" align="align">
        <el-button type="text">进入</el-button>
      </el-table-column>
    </el-table>
    <div class="pagination">
      共<span>{{ pageTotal }}</span
      >条数据
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { HistoryList } from "../../../../../api/UserMable";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      view: false,
      //表格数据
      tableData: [],
      pageTotal: 0,
    };
  },
  methods: {
    getData() {
      let UserInfo = this.$store.state.UserInfo;
      let UserMobile = UserInfo.PhoneMobile;
      HistoryList({ UserMobile }).then((res) => {
        if (res.code == 0) {
          this.pageTotal = res.number;
          this.tableData = res.sum;
        } else {
          ElMessage.warning({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    handlePageChange(val) {
      this.loading = true;
      this.query.pageIndex = val;
      this.getData();
    },
    save() {},
    open() {
      this.getData();
      this.view = true;
    },
    close() {
      this.view = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  height: 320px;
}

.PenName {
  width: 350px;
}

.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  color: #606060;
  font-size: 14px;
  span {
    margin-left: 5px;
    margin-right: 5px;
  }
}
</style>
