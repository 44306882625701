<template>
  <el-dialog v-model="view" width="800px" title="设置">
    <el-tabs :tab-position="tabPosition" style="height: 500px">
      <el-tab-pane label="帐号与密码"> <Account></Account></el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import Account from "./Account";

export default {
  components: {
    Account,
  },
  data() {
    return {
      tabPosition: "left",
      view: false,
    };
  },
  methods: {
    save() {},
    open() {
      this.view = true;
    },
    close() {
      this.form = {};
      this.view = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  height: 320px;
}
.PenName {
  width: 350px;
}
</style>
