<template>
  <el-dialog v-model="view" width="600px" title="收藏夹">
    <div v-if="tableStart">
      <div class="t">
        <div class="black">{{ tableName }}</div>
        <div class="blacks">创建时间：{{ tableTime }}</div>
      </div>
      <el-button @click="black">返回</el-button>
    </div>

    <el-button @click="newlist" v-if="!tableStart">创建收藏夹</el-button>
    <el-table :data="GroupingData" height="300px" v-if="!tableStart">
      <el-table-column prop="Name" label="名称"></el-table-column>
      <el-table-column prop="Content.length" label="数量"></el-table-column>
      <el-table-column prop="NewTime" label="创建时间时间"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button type="text" @click="Enter(scope.$index)">进入</el-button>
          <el-button class="red" type="text" @click="remove(scope.$index)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-table :data="tableData" v-if="tableStart" height="300px">
      <el-table-column prop="Title" label="文章名称"></el-table-column>
      <el-table-column prop="TeamName" label="团队名称"></el-table-column>
      <el-table-column prop="NewTime" label="收藏时间"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  CollectNew,
  CollectList,
  CollectRemove,
} from "../../../../../api/UserMable";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      view: false,
      //分组数据
      GroupingId: "",
      GroupingData: [],
      //表格数据
      tableData: [],
      tableTime: "",
      tableName: "",
      //详细列表状态
      tableStart: false,
    };
  },
  methods: {
    // 删除一级列表
    remove(row) {
      let GroupingId = this.GroupingId;
      let index = row;

      this.$confirm("此操作将永久删除该确认夹，请确认", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          CollectRemove({ GroupingId, index }).then((res) => {
            if (res.code == 0) {
              ElMessage.success({
                message: res.message,
                type: "success",
              });
              this.getData();
            } else {
              ElMessage.warning({
                message: res.message,
                type: "warning",
              });
            }
          });
        })
        .catch();
    },
    //返回一级列表
    black() {
      this.tableStart = false;
      this.tableData = [];
      this.tableName = "";
    },
    //进入详细列表
    Enter(row) {
      let GroupingData = JSON.parse(JSON.stringify(this.GroupingData));
      this.tableData = GroupingData[row].Content;
      this.tableName = GroupingData[row].Name;
      this.tableTime = GroupingData[row].NewTime;
      this.tableStart = true;
    },
    getData() {
      let UserInfo = this.$store.state.UserInfo;
      let PhoneMobile = UserInfo.PhoneMobile;
      CollectList({ PhoneMobile }).then((res) => {
        let sum = res.sum;
        if (sum) {
          let List = sum.List;
          let _id = sum._id;
          console.log(_id);
          this.GroupingId = _id;
          this.GroupingData = List;
        }
      });
    },
    newlist() {
      this.$prompt("请输入名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let UserInfo = this.$store.state.UserInfo;
          let PhoneMobile = UserInfo.PhoneMobile;
          let Name = value;
          CollectNew({ PhoneMobile, Name }).then((res) => {
            if (res.code == 0) {
              ElMessage.success({
                message: res.message,
                type: "success",
              });
              this.getData();
            } else {
              ElMessage.warning({
                message: res.message,
                type: "warning",
              });
            }
          });
        })
        .catch();
    },
    save() {},
    open() {
      this.getData();
      this.view = true;
    },
    close() {
      this.view = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  height: 320px;
}
.red {
  color: red;
}
.t {
  margin-bottom: 20px;
}
.black {
  margin-right: 20px;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  text-indent: 10px;
}

.blacks {
  margin-right: 17px;
  font-size: 13px;
  color: #606060;
  display: inline-block;
}
</style>
