<template>
  <el-form class="form" label-width="70px" label-position="top">
    <el-form-item label="帐号设置">
      注：为保证帐号安全，需进行二次验证。
    </el-form-item>
    <el-form-item label="密码" class="powOb">
      <div>
        已设置<el-button type="text" class="pow" @click="openpow">{{
          pow.text
        }}</el-button>
      </div>
      <div v-if="pow.start">
        <el-form-item>
          <el-input
            show-password
            v-model="form.NewPassword"
            class="Password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            show-password
            v-model="form.NewPasswords"
            class="Password"
            placeholder="请重新输入新密码"
          ></el-input>
        </el-form-item>
        <el-button class="sava" @click="sava">保存</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { ElMessage } from "element-plus";
import { SetUpPassword } from "../../../../../api/UserMable";

export default {
  data() {
    return {
      form: {
        NewPassword: "",
        NewPasswords: "",
      },
      pow: {
        text: "编辑",
        start: false,
      },
    };
  },
  methods: {
    sava() {
      let NewPassword = this.form.NewPassword;
      let NewPasswords = this.form.NewPasswords;
      if (!NewPassword) {
        ElMessage.warning({
          message: "新密码为空",
          type: "warning",
        });
        return;
      }

      if (!NewPasswords) {
        ElMessage.warning({
          message: "重新输入密码为空",
          type: "warning",
        });
        return;
      }

      if (NewPassword == NewPasswords) {
        let _id = this.$store.state.UserInfo._id;
        SetUpPassword({ _id, NewPassword }).then((res) => {
          if (res.code == 0) {
            this.openpow();
            ElMessage.success({
              message: res.message,
              type: "success",
            });
          } else {
            ElMessage.warning({
              message: res.message,
              type: "warning",
            });
          }
        });
      } else {
        ElMessage.warning({
          message: "密码不一致",
          type: "warning",
        });
      }
    },
    openpow() {
      if (this.pow.start) {
        this.pow.start = false;
        this.pow.text = "编辑";
      } else {
        this.pow.start = true;
        this.pow.text = "收起";
        this.close();
      }
    },
    close() {
      this.form.NewPassword = "";
      this.form.NewPasswords = "";
    },
  },
};
</script>

<style scoped lang="scss">
.pow {
  margin-left: 20px;
}
.powOb {
  text-indent: 2rem;
}
.Password {
  width: 300px;
}
.sava {
  margin-left: 2rem;
  display: inline;
}
</style>
